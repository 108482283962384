import React from "react";

import type { Meta } from "@/__main__/router.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import StatisticsARAM from "@/game-lol/StatisticsARAM.jsx";
import StatisticsArena from "@/game-lol/StatisticsArena.jsx";
import StatisticsArenaDuos from "@/game-lol/StatisticsArenaDuos.jsx";
// import StatisticsNexusBlitz from "@/game-lol/StatisticsNexusBlitz.jsx";
// import StatisticsOneForAll from "@/game-lol/StatisticsOneForAll.jsx";
import StatisticsOverview from "@/game-lol/StatisticsOverview.jsx";
import StatisticsSynergies from "@/game-lol/StatisticsSynergies.jsx";
import { getPatchForArenaData } from "@/game-lol/utils/arena-queue-utils.mjs";
import { patchDisplay } from "@/game-lol/utils/champions-stats-utils.mjs";
import {
  getCurrentPatchForStaticData,
  prettyPatch,
} from "@/game-lol/utils/util.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

interface Tab {
  component: React.ReactNode;
  url: string | (() => string);
  title: Translation;
}

const TABS = {
  overview: {
    component: <StatisticsOverview />,
    url: () => `/${lolRefs.lolChampionsPrefix}/overview`,
    title: ["lol:meta.champions.titleOverview", "LoL Stats"],
  },
  aram: {
    component: <StatisticsARAM />,
    url: () => `/${lolRefs.lolChampionsPrefix}/aram`,
    title: ["lol:meta.champions.titleAram", "ARAM Stats"],
  },
  // "one-for-all": {
  //   component: <StatisticsOneForAll />,
  //   url: () => `/${lolRefs.lolChampionsPrefix}/one-for-all`,
  //   title: ["lol:meta.champions.lolTitleOneForAll", "LoL One For All Stats"],
  // },
  arena: {
    component: <StatisticsArena />,
    url: () => `/${lolRefs.lolChampionsPrefix}/arena`,
    title: ["lol:meta.champions.lolTitleArena", "LoL Arena Stats"],
  },
  "arena-duos": {
    component: <StatisticsArenaDuos />,
    url: () => `/${lolRefs.lolChampionsPrefix}/arena-duos`,
    title: ["lol:meta.champions.lolTitleArenaDuos", "LoL Arena Duo Stats"],
  },
  // "nexus-blitz": {
  //   component: <StatisticsNexusBlitz />,
  //   url: () => `/${lolRefs.lolChampionsPrefix}/nexus-blitz`,
  //   title: ["lol:meta.champions.titleNexusBlitz", "Nexus Blitz Stats"],
  // },
  // urf: {
  //   component: <StatisticsURF />,
  //   url: () => `/${lolRefs.lolChampionsPrefix}/urf`,
  //   title: ["lol:meta.champions.titleUrf", "URF Stats"],
  // },
  synergies: {
    component: <StatisticsSynergies />,
    url: () => `/${lolRefs.lolChampionsPrefix}/synergies`,
    title: ["lol:championsPage.tabs.synergies", "Synergies"],
  },
} satisfies Record<string, Tab>;

function Champions() {
  const {
    parameters: [tab],
  } = useRoute();
  const currTab = TABS[tab];

  if (!currTab) return <h1>{tab}</h1>;

  return (
    <PageContainer>
      <PageHeader
        title={currTab.title}
        links={Object.values(TABS).map(({ url, title }) => ({
          url: typeof url === "function" ? url() : url,
          text: title,
        }))}
      />
      {currTab.component && currTab.component}
    </PageContainer>
  );
}

export function meta(params): Meta {
  const tabId = params[0];

  const patch = prettyPatch(getCurrentPatchForStaticData());
  const arenaPatch = getPatchForArenaData();

  let title: [string, string, Record<string, unknown>?] = [
    "lol:meta.champions.title",
    "All LoL Champions: Builds, Guides, Counters & More",
  ];
  let description: [string, string, Record<string, unknown>?] = [
    "lol:meta.champions.description",
    "Builds, stats, tips, and counters for every League of Legends Champion.",
  ];

  switch (tabId) {
    case "overview":
      title = ["lol:meta.champions.titleOverview", "LoL Stats"];
      description = [
        "lol:meta.champions.subtitleOverview",
        "Understand the League of Legends Ranked Solo mode with a look at all LoL solo champions. View all LoL Ranked Solo stats and champion performance data for Patch {{patch}}.",
        { patch: patchDisplay(patch, true) },
      ];
      break;
    case "aram":
      title = ["lol:meta.champions.titleAram", "ARAM Stats"];
      description = [
        "lol:meta.champions.subtitleAram",
        "Understand the League of Legends ARAM mode with a look at all LoL ARAM champions. View all LoL ARAM stats and champion performance data for Patch {{patch}}.",
        { patch: patchDisplay(patch, true) },
      ];
      break;
    case "one-for-all":
      title = ["lol:meta.champions.titleOneForAllStats", "One For All Stats"];
      description = [
        "lol:meta.champions.subtitleOneForAll",
        "Understand the League of Legends One For All mode with a look at all LoL One For All champions. View all LoL One For All stats and champion performance data for Patch {{patch}}.",
        { patch: patchDisplay(patch, true) },
      ];
      break;
    case "arena":
      title = ["lol:meta.champions.titleArena", "Arena Stats"];
      description = [
        "lol:meta.champions.subtitleArena",
        "Understand the League of Legends Arena 2v2v2v2 mode with a look at all LoL Arena champions. View all LoL Arena stats and champion performance data for Patch {{patch}}.",
        { patch: patchDisplay(arenaPatch, true) },
      ];
      break;
    case "arena-duos":
      title = ["lol:meta.champions.titleArenaDuos", "Arena Duo Stats"];
      description = [
        "lol:meta.champions.subtitleArenaDuos",
        "Understand the League of Legends Arena 2v2v2v2 mode with a look at all LoL Arena duos champions. View all LoL Arena duos stats and champion performance data for Patch {{patch}}.",
        { patch: patchDisplay(arenaPatch, true) },
      ];
      break;
    case "nexus-blitz":
      title = ["lol:meta.champions.titleNexusBlitz", "Nexus Blitz Stats"];
      description = [
        "lol:meta.champions.subtitleNexusBlitz",
        "Dive deep into LoL Nexus Blitz stats, providing the latest insights on champion pick rate and win rate. Stay updated with Nexus Blitz stats for patch {{patch}}.",
        { patch: patchDisplay(patch, true) },
      ];
      break;
  }

  return {
    title,
    description,
    subtitle: true,
  };
}

export default Champions;
