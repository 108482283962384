import React from "react";
import { css } from "goober";

import useChampionsFilter from "@/game-lol/utils/useChampionsFilter.jsx";
import useChampionsOverview from "@/game-lol/utils/useChampionsOverview.jsx";
import DataTable from "@/shared/DataTable.jsx";
import ClientOnly from "@/util/ClientOnly.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const ChampionTable = () => css`
  .champion-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .champion-name {
    width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .champion-matchups {
    display: flex;
    gap: 0.25rem;
  }
`;

function StatisticsOverview() {
  const { searchParams } = useRoute();
  const { FilterBar, ...filterParams } = useChampionsFilter({
    tab: "overview",
  });

  const { rows, cols } = useChampionsOverview(searchParams, filterParams);

  return (
    <>
      {FilterBar}
      <ClientOnly>
        <DataTable
          cols={cols}
          rows={rows}
          indexCol
          className={ChampionTable()}
          sortCol={2} // Tier
          sortColTiebreak={3} // Win Rate
          sortDir="DESC"
          searchText={filterParams.searchText}
          searchCol={1}
        />
      </ClientOnly>
    </>
  );
}

export default StatisticsOverview;
