import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import { commonChampionsStats } from "@/game-lol/utils/champions-stats-utils.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const useChampionsARAM = (searchParams) => {
  const { t } = useTranslation();
  const filters = getDefaultedFiltersForChampions(searchParams, "aram");
  const urlParams = getSearchParamsForChampions(false, filters);

  const {
    lol: { championsStats },
  } = useSnapshot(readState);

  const stats = championsStats?.[urlParams.toString()];

  const champions = getStaticData("champions");

  const rows = useMemo(() => {
    if (!champions || !stats || stats instanceof Error) return null;

    return commonChampionsStats([...stats]).map((champStats) => {
      const {
        champion_id: championId,
        champion_role_pickrate: pickRate,
        champion_role_tier: tierListTier,
        games,
        win_rate: wr,
      } = champStats;

      const championKey = champions?.keys?.[championId];
      const champion = champions?.[championKey];

      const linkParams = new URLSearchParams(searchParams);

      const championName = champion?.name;
      const tier = tierListTier;
      const TierIcon = getTierIcon(tier);

      return [
        {
          display: (
            <>
              <ChampionImg id={championId} size={28} />
              <span className="champion-name">{championName || ""}</span>
            </>
          ),
          value: championName,
          className: "champion-meta",
          link: `/${lolRefs.lolChampionPrefix}/${championKey || championId}/aram?${linkParams}`,
        },
        {
          display: <img src={TierIcon} width="28" height="28" />,
          value: tier * -1,
        },
        {
          display: getLocaleString(wr, percentOptions),
          value: wr,
          // style: { color: getWinRateColor(wr * 100) },
        },
        { display: getLocaleString(pickRate, percentOptions), value: pickRate },
        { display: getLocaleString(games), value: games },
      ];
    });
  }, [stats, champions, searchParams]);

  return {
    cols: [
      { display: t("lol:champion", "Champion"), align: "left", primary: true },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:winRate", "Win Rate"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:pickRate", "Pick Rate"),
        align: "right",
        isStat: true,
      },
      { display: t("common:matches", "Matches"), align: "right", isStat: true },
    ],
    rows: rows,
  };
};

export default useChampionsARAM;
