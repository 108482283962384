import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import {
  COMMON_CHAMPIONS_PICKRATE,
  commonChampionsStats,
} from "@/game-lol/utils/champions-stats-utils.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon-path.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import getTierIconPath from "@/shared/get-tier-icon-path.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
} as Intl.NumberFormatOptions;

const commonColumnCss = `
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--shade1);
  font-size: var(--sp-3_5);
`;

// TODO (Rio): ZzZzZzZz these need to be removed
export const cssRank = () => css`
  color: var(--shade0);
`;
export const cssChampionName = () => css`
  ${commonColumnCss};
  justify-content: flex-start;
  color: var(--shade0);
  padding-inline: var(--sp-2);

  .championKey {
    display: flex;
    align-items: center;
    gap: var(--sp-4);

    ${mobile} {
      gap: var(--sp-3);
    }
  }

  span {
    color: var(--shade0);
  }
`;

const useChampionsOverview = (searchParams, filterParams) => {
  const filters = getDefaultedFiltersForChampions(
    searchParams,
    "overview",
    null,
  );
  const urlParams = getSearchParamsForChampions(false, filters);

  const { t } = useTranslation();
  const {
    lol: { championsStats },
  } = useSnapshot(readState);

  const stats = championsStats?.[urlParams.toString()];
  const filterRoleSymbol = RoleSymbol(filterParams.role);

  const champions = getStaticData("champions");

  const rows = useMemo(() => {
    if (!stats || stats instanceof Error) return null;

    // If there is a search text, we don't want to show common champions, show everything
    const requiredPickRate = filterParams.searchText.length
      ? 0
      : COMMON_CHAMPIONS_PICKRATE;
    /* @ts-ignore */
    const baseData = commonChampionsStats(stats, requiredPickRate);

    return baseData.map((champStats) => {
      const {
        champion_id: championId,
        individual_position: role,
        champion_ban_rate: banRate,
        champion_role_pickrate: pickRate,
        champion_role_tier: tierListTier,
        games,
        win_rate: wr,
        weak_against = [],
        previous_patch_stats,
      } = champStats;

      const roleString =
        role && role !== ROLE_SYMBOLS.all
          ? ROLE_SYMBOL_TO_STR[role]?.gql
          : null;

      const championKey = champions?.keys?.[championId];
      const champion = champions?.[championKey];

      const linkParams = new URLSearchParams(searchParams);
      if (roleString) {
        linkParams.set("role", roleString);
      }

      const championName = champion?.name;
      const RoleIcon = role && getRoleIcon(role);
      const tier = tierListTier;
      const TierIcon = tier && getTierIconPath(tier);

      const wrChange = wr - previous_patch_stats.win_rate;
      const wrChangePrefix = wrChange > 0 ? "+" : "";

      return [
        {
          display: RoleIcon ? (
            <img src={RoleIcon} width="24" height="24" />
          ) : null,
          value: roleString,
          misc: {
            role,
          },
        },
        {
          display: (
            <>
              <ChampionImg id={championId} size={28} />
              <span className="name-string champion-name">{championName}</span>
            </>
          ),
          value: championName,
          className: "champion-meta",
          link: `/${lolRefs.lolChampionPrefix}/${championKey || championId}/build?${linkParams}`,
          misc: {
            championId,
          },
        },
        {
          display: <img src={TierIcon} width="28" height="28" />,
          value: tier * -1,
        },
        {
          display: getLocaleString(wr, percentOptions),
          value: wr,
          // style: { color: getWinRateColor(wr * 100) },
        },
        {
          display: `${wrChangePrefix}${getLocaleString(wrChange, percentOptions)}`,
          value: wrChange,
          style: {
            opacity: wrChange > -0.015 && wrChange < 0.015 ? 0.38 : 1,
          },
        },
        {
          display: getLocaleString(banRate, percentOptions),
          value: banRate,
        },
        {
          display: getLocaleString(pickRate, percentOptions),
          value: pickRate,
        },
        { display: getLocaleString(games), value: games },
        {
          display: (
            <div className="champion-matchups">
              {weak_against.slice(0, 4).map((id) => (
                <ChampionImg key={id} id={id} size={20} />
              ))}
            </div>
          ),
          value: null,
        },
      ];
    });
  }, [stats, champions, searchParams, filterParams.searchText]);

  const filteredData = useMemo(() => {
    if (!rows) return null;

    return rows
      .filter((v) => {
        if (!filterRoleSymbol || filterRoleSymbol === ROLE_SYMBOLS.all)
          return true;
        const champRoleSymbol = v[0].value;
        return RoleSymbol(champRoleSymbol) === RoleSymbol(filterRoleSymbol);
      })
      .sort((a, b) => {
        // Default Sort: tier, tie-break with winrate
        return b?.[2]?.value - a?.[2]?.value || b?.[3]?.value - a?.[3]?.value;
      });
  }, [rows, filterRoleSymbol]);

  return {
    cols: [
      { display: t("lol:role", "Role"), align: "center" },
      { display: t("lol:champion", "Champion"), align: "left", primary: true },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:winRate", "Win Rate"),
        align: "center",
        isStat: true,
      },
      {
        display: t("common:wrChange", "WR Change"),
        align: "center",
        isStat: true,
      },
      {
        display: t("common:banRate", "Ban Rate"),
        align: "center",
        isStat: true,
      },
      {
        display: t("common:pickRate", "Pick Rate"),
        align: "center",
        isStat: true,
      },
      { display: t("common:matches", "Matches"), align: "right", isStat: true },
      { display: t("common:counteredBy", "Countered By"), align: "right" },
    ],
    rows: filteredData,
  };
};

export default useChampionsOverview;
